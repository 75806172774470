@import "../../variables";

.main_all{
    overflow: hidden;
}

.main{
    height: 27.25rem;
    padding-top: 1.25rem;

    .main_slide_area{
        width: 42.25rem;
        min-width: 42.25rem;
        height: 23.5rem;
        border-radius: 0.75rem;
        overflow: hidden;

        .main_slider{
            visibility: hidden;
            width: 100%;
            max-width:100%;
            margin-bottom: 0;

            .main_slide{
                position: relative;
                min-width: 42.25rem;
                height: 23.5rem;
                overflow: hidden;
            
                .text{
                    padding-top: 4.125rem;
                    padding-left: 2.5rem;
                    z-index: 1;

                    h4{
                        max-width: 20rem;
                        font-size: 1.625rem;
                        font-weight: 700;

                        span{
                            font-weight: 400;
                        }
                    }

                    p{
                        max-width: 19.5rem;
                        font-size: $body-text-18;
                        color: $graytext;
                        padding-top: 1.125rem;
                    }


                    .logo{
                        width: 10.9375rem;
                        height: 1.8125rem;
                    }
                }

                img{
                    position: absolute;
                }
            }

            .slick-dots{
                left: 2.5rem;
                bottom: 2.5rem;
                display: flex;
                gap: 0.25rem;
                align-items: center;
                width: auto;
                padding: 0.75rem 0.625rem;
                padding-right: calc(1.375rem);
                background-color: rgba(0, 0, 0, 0.40);;
                z-index: 200;
                border-radius: 6.875rem;

                li{
                    width: 8px;
                    height: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0;
                    transition: 0.2s;

                    &.slick-active{
                        width: 14px;

                        button{
                            width: 12px;
                        }
                    }

                    button{
                        width: 6px;
                        height: 6px;
                        padding: 0;
                        background-color: $white;
                        border-radius: 89px;
                        transition: 0.2s;

                        &::before{
                            display: none;
                        }
                    }
                }
            }

            .play-button{
                position: absolute;
                bottom: 2.75rem;
                left: calc(6.5rem);
                padding: 0.75rem 0;
                background-image: url('/images/main/icons/icon_stop.svg');
                width: 0.5rem;
                height: 0.5rem;
                background-position: center;
                background-repeat: no-repeat;
                z-index: 300;

                &.stop{
                    background-image: url('/images/main/icons/icon_play.svg');
                }
            }
        }
    }
}

.event_banner{
    width: 20.75rem;
    height: 23.5rem;

    a{
        height: 100%;
    }

    .banner{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 2.06rem 1.38rem;
        border-radius: 0.625rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        
        &.banner1{
            background-image: url('/images/main/banner/main_banner1.png');

            .banner_title{
                p{
                    color: #1370F1;;
                }
            }
        }

        &.banner2{
            background-image: url('/images/main/banner/main_banner2.png');

            .banner_title{
                p{
                    color: #5E63DA;
                }
            }
        }

        &.banner3{
            background-image: url('/images/main/banner/main_banner3.png');
            justify-content: flex-start;
            align-items: center;
            position: relative;

            .banner_title{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                
                p{
                    text-align: center;
                    font-size: 1.875rem;
                    color: $white;
                    
                    &:nth-child(1){
                        font-weight: 700;
                    }
                    
                    &:nth-child(2){
                        width: 13.875rem;
                        letter-spacing: 0.9rem;
                    }

                    &:nth-child(3){
                        font-weight: 700;
                    }
                    
                    &.ko{
                        &:nth-child(3){
                            letter-spacing: 0.1875rem;
                        }
                    }
                }
            }

            .banner_text{
                position: absolute;
                bottom: 8.5rem;
                right: 1.25rem;
                width: 7.9375rem;
                color: $white;
                font-size: 0.875rem;
                font-weight: 700;
            }
        }

        &.banner1, &.banner2{
            .tag{
                width: fit-content;
                display: block;
                padding: 0.25rem 0.75rem;
                gap: 0.625rem;
                border-radius: 3rem;
                background-color: $black;
                color: $white;
                font-size: 0.75rem;
            }
    
            .banner_text{
                display: flex;
                align-items: center;
                width: 14rem;
                max-width: 14rem;
                padding: 0.5rem 0;
                font-size: $body-text-12;
            }
    
            .banner_title{
                position: relative;
                font-size: 1.25rem;
                font-weight: 800;
                max-width: 14.5rem;
    
                p{
                    line-height: 1.3;
                }
            }
        }

    }
}

.recruit{
    .container{
        padding-bottom: 4.375rem;

        .recruit_box{
            width: 100%;
            padding: 3.75rem 2.19rem;
            border: 1px solid $bordergray;
            border-radius: 0.75rem;
            
            .title{
                text-align: center;
    
                h3{
                    margin-bottom: 0.75rem;
                    font-weight: 700;
                }
    
                p{
                    font-size: $body-text-18;
                    color: $gray-700;
                }
            }
    
            .recruit_items{
                margin: 2.5rem 0;
                gap: 0.75rem;
    
                .item{
                    width: 7.875rem;
                    height: 9.125rem;
                    padding: 0.81rem;
                    cursor: pointer;
                    background-color: $white;
                    border: 1px solid $bordergray;
                    border-radius: 0.375rem;
                    transition: 0.2s;
                    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0);
    
                    img{
                        margin-bottom: 0.5rem;
                        transition: 0.2s;
                    }

                    p{
                        text-align: center;
                        display: flex;
                        align-items: center;
                        height: 2.75rem;
                        font-size: $body-text-14;
                    }
    
                    &:hover{
                        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.10);
                    }

                    &:focus{
                        background-color: $lightgray;
                    }
                }
            }

            .more_recruit{
                width: fit-content;
                height: 1.375rem;
    
                a{
                    background-color: $mainblue;
                    padding: 0.75rem 1.75rem;
                    color: $white;
                    font-size: 1.1rem;  
                    transition: 0.2s;
                    border-radius: 4.375rem;
                    transition: 0.2s;
    
                    &:hover{
                        background-color: $buttonblue;
                    }
                }
            }
        }
    }
}

.slider{
    background-color: $white_cyan;

    .container{
        padding-top: 5.5rem;
        padding-bottom: 5.5rem;

        .slider_box{
            position: relative;
            padding: 0 0.75rem;

            .buttonSlider{
                text-align: end;
    
                span{
                    color: $gray-700;;
                }
    
                .custom-button{
                    color: $gray-700;
    
                    &:hover, &.active{
                        color: $info;
                        font-weight: 700;
                        border-bottom: 2px solid $info;
                    }
                }
            }
    
            .arrowSlider{
                position: absolute;
                bottom: 0;
    
                span{
                    display: block;
                    width: 1.75rem;
                    height: 1.75rem;
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    z-index: 200;
                    transition: 0.2s;
    
                    &.prev{
                        margin-right: 0.875rem;
                        background-image: url('/images/icons/arrow-left.svg');
    
                        &:hover{
                            background-image: url('/images/icons/arrow-left-hover.svg');
                        }
                    }
    
                    &.next{
                        background-image: url('/images/icons/arrow-right.svg');
    
                        &:hover{
                            background-image: url('/images/icons/arrow-right-hover.svg');
                        }
                    }
                }
            }
    
            #cardSlider{
                .slider{
                    padding: 2.5rem 0.75rem;
    
                    .slider_text{
                        padding-right: 0.625rem;
    
                        .tag{
                            width: fit-content;
                            margin-bottom: 1rem;
                            padding: 0.5rem 1.125rem;
                            font-size: 1rem;
                            background-color: $info;
                            color: $white;
                            border-radius: 50px;
                        }
    
                        .title{
                            font-size: 1.5rem;
                            font-weight: 700;
                        }
                    }
    
                    .card_group{
                        .card_item{
                            padding-left: 1.5rem;
    
                            .card_text{
                                margin-top: 0.75rem;
                                
                                h5{
                                    margin-bottom: 0.5rem;
                                    font-weight: 700;
                                }
    
                                p{
                                    color: $gray-700;
                                }
                            }
                            
                            .card-body{
                                position: relative;
    
                                &:hover{
                                    .card-desc{
                                        opacity: 1;
                                    }
                                }
    
                                .card-img{
                                    img{
                                        width: 100%;
                                    }
                                }
    
                                .card-desc{
                                    position: absolute;
                                    top: 0;
                                    width: 100%;
                                    height: 100%;
                                    padding: 1.25rem;
                                    background-color: rgba($black, 0.7);
                                    border-radius: 10px;
                                    color: $white;
                                    font-size: 1.1rem;
                                    font-weight: 700;
                                    transition: 0.2s;
                                    opacity: 0;
                                    
                                    i{
                                        position: absolute;
                                        bottom: 1.25rem;
                                        right: 1.25rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.story{
    .container{
        .story_box{
            padding: 5rem 0;

            .story_title{
                margin-bottom: 2.5rem;

                &>div{
                    h3{
                        font-weight: 700;
                        padding-bottom: 0.75rem;
                    }

                    p{
                        font-size: $body-text-18;
                        color: $graytext;
                        max-width: 45rem;
                    }
                }

                .more_content{
                    a{
                        display: block;
                        padding: 0.5rem 1.25rem;
                        background-color: $mainblue;
                        border-radius: 2.4375rem;
                        color: $white;
                        transition: 0.2s;

                        &:hover{
                            background-color: $buttonblue;
                        }   
                    }
                }
            }

            .list_story{
                gap: 1.125rem;

                .post{
                    width: calc((100% - 4rem) / 3);

                    .card{
                        button{
                            padding: 0;
                        }

                        .story_img{
                            height: 13.04131rem;
                            aspect-ratio: 184 / 122;
                            border-radius: 0.75rem 0.75rem 0rem 0rem;
                            overflow: hidden;
                        }

                        .card-body{
                            padding: 1.25rem 0.75rem;
                            padding-bottom: 2.125rem;
                            background-color: #F4F6FB;
                            border-radius: 0rem 0rem 0.75rem 0.75rem;

                            .category{
                                padding: 0;
                                padding-bottom: 0.5rem;
                                font-size: $body-text-16;
                            }

                            .title{
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 1;
                                align-self: stretch;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                font-size: $body-text-18;
                                margin-bottom: 1.125rem;
                            }

                            .writer_name{
                                font-size: $body-text-16;
                                
                                a{
                                    color: $graytext;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.email{
    background-color: #0B5AA4;

    .container{
        padding: 7.75rem 0.75rem;

        h3{
            margin-bottom: 4.0625rem;
            text-align: center;
            font-size: 2rem;
            font-weight: 700;
            color: $white;
        }

        .email_form{
            position: relative;
            
            i, button{
                position: absolute;
                z-index: 10;
            }

            i{
                top: calc(50% - 1.5rem);
                left: 1.25rem;
                color: $info;
                font-size: 2rem;
            }

            button{
                top: calc(50% - 1.375rem);
                padding: 0.625rem 1rem;
                right: 1.25rem;
                border-radius: 5px;
                color: $white;
                background-color: $info;
                font-size: 1.1rem;

                &:disabled{
                    background-color: $gray-300;
                    color: $gray-550;
                }
            }

            input{
                width: 36.0625rem;
                height: 4.0625rem;
                padding: 0.75rem 6rem 0.75rem 4.125rem;
                font-size: 1.25rem;

                &::placeholder{
                    color: $gray-550;
                }
            }
        }
    }
}
 /* 부트스트랩에서 제공하는 유효성 상태 스타일 덮어쓰기 */
.form-control{
    &.is-invalid{
        background-image: none; /* 에러 메시지 감추기 */

        &:focus{
            background-image: none;
        }
    }
}

.link{
    background-color: $white_cyan;

    .container{
        padding: 5rem 0.75rem;
        padding-bottom: 6.875rem;

        .list_links{
            h3{
                font-weight: 700;
                margin-bottom: 1.875rem;
            }

            .list_link{
                display: flex;
                flex-wrap: wrap;
                gap: 1rem;

                .list_box{
                    width: calc(50% - 0.5rem);

                    .card{
                        margin-bottom: 0;
                        padding: 1.125rem 1rem;
                        border-radius: 10px;
                        
                        h6{
                            margin-bottom: 0;
                        }

                        span{
                            display: block;
                            width: 1.75rem;
                            height: 1.75rem;
                            background-image: url('/images/main/icons/go.svg');
                            background-size: cover;
                            background-repeat: no-repeat;
                            transition: 0.2s;
                        }

                        &:hover{
                            span{
                                background-image: url('/images/main/icons/go-hover.svg');
                            }
                        }
                    }
                    
                }
            }
        }
    }
}








